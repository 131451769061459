export const GETLIST = "USER:GET-LIST";
export const GETLISTPENDING = "USER:GET-LIST-PENDING";
export const CREATEUSER = "USER:CREATE-USER";
export const ADDCREATEUSER = "USER:ADD-USER";
export const TRANSFERMONEY = "USER:TRANSFER-MONEY";
export const FETCHUSERBYID = "USER:FETCH-USER-BY-ID";
export const FETCHUSERBYIDSUCCESS = "USER:FETCH-USER-BY-ID_SUCCESS";
export const FETCHUSERBYADMINID = "USER:FETCH-PROFILE-BY-ADMIN-ID";
export const FETCHUSERBYADMINIDSUCCESS =
  "USER:FETCH-PROFILE-BY-ADMIN-ID_SUCCESS";
