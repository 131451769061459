import {
  CITIES_GET_LIST,
  CITIES_GET_LIST_PENDING,
  DISTRICT_BY_CITIES_ID,
  DISTRICT_BY_CITIES_ID_PENDING,
  WARD_BY_DISTRICT_ID,
  WARD_BY_DISTRICT_ID_PENDING,
} from "redux/constants/address";

export const getListCities = (params) => {
  return { type: CITIES_GET_LIST_PENDING, params };
};

export const listCities = (data, pages) => {
  return { type: CITIES_GET_LIST, data, pages };
};

export const getListDistrictByCityId = (params) => {
  return { type: DISTRICT_BY_CITIES_ID_PENDING, params };
};

export const listDistrictByCityId = (data, pages) => {
  return { type: DISTRICT_BY_CITIES_ID, data, pages };
};
export const getListWardByDistrictId = (params) => {
  return { type: WARD_BY_DISTRICT_ID_PENDING, params };
};

export const listWardByDistrictId = (data, pages) => {
  return { type: WARD_BY_DISTRICT_ID, data, pages };
};
