/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import useBodyClass from "hooks/useBodyClass";
import { fetchUserProfileByAdminId } from "redux/actions";

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => (
        <React.Fragment>
          {isAuthenticated && children}
          {!isAuthenticated && (
            <Redirect
              to={{ pathname: AUTH_PREFIX_PATH, state: { from: location } }}
            />
          )}
        </React.Fragment>
      )}
    />
  );
}

export const Views = (props) => {
  const { locale, token, location, direction, fetchUserProfileByAdminId } =
    props;
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);

  useEffect(() => {
    if (token) _getUserProfile();
  }, [token]);

  const _getUserProfile = () => {
    fetchUserProfileByAdminId?.();
  };

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/">
            <Redirect to={APP_PREFIX_PATH} />
          </Route>

          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>

          <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
            <AppLayout direction={direction} location={location} />
          </RouteInterceptor>

          <Redirect to={{ pathname: AUTH_PREFIX_PATH }} />
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { token } = auth;
  return { locale, direction, token };
};

const mapDispatchToProps = {
  fetchUserProfileByAdminId: fetchUserProfileByAdminId,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Views));
