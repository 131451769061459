import service from "auth/FetchInterceptor";
import axios from "axios";

const UserService = {};

UserService.getListUsers = (params) => {
  return service.post("/users/list", params);
  // return axios.post("/users/list", params);
};

UserService.login = (params) => {
  return service.post("/users/login", params);
  // return axios.post("/users/login", params);
};

UserService.createUser = (params) => {
  return service.post("/users/register", params);
  // return axios.post("/users/register", params);
};

UserService.fetchUserDetailById = (params) => {
  return service.post("/users/user-profile", params);
  // return axios.post("/users/user-profile", params);
};

UserService.updateUserProfileByUserId = (params) => {
  return service.post("/users/update-user-profile-by-user-id", params);
  // return axios.post("/users/update-user-profile", params);
};

UserService.createUserByAdmin = (params) => {
  return service.post("/users/admin/create", params);
  // return axios.post("/users/admin/create", params);
};

UserService.fetchUserProfileByAdmin = () => {
  return service.post("/users/admin/profile");
};

UserService.updateAdminProfilee = (params) => {
  return service.post("/users/update-user-profile", params);
};

export default UserService;
