import { all } from "redux-saga/effects";
import Auth from "./Auth";
import User from "./User";
import Motels from "./Motels";
import MotelType from "./motelType";
import Address from "./address";
import Report from "./report";
import ObjectRent from "./ObjectRent";
export default function* rootSaga(getState) {
  yield all([
    Auth(),
    User(),
    Motels(),
    MotelType(),
    Address(),
    Report(),
    ObjectRent(),
  ]);
}
