import { GET_LIST_MOTEL_TYPE } from "redux/constants/MotelType";

const initState = {
  motelType: {
    data: [],
    pages: 0,
  },
};

const motels = (state = initState, action) => {
  const { data = [], pages = 0 } = action;
  switch (action.type) {
    case GET_LIST_MOTEL_TYPE:
      return { ...state, motelType: { ...state.motelType, data, pages } };
    default:
      return state;
  }
};

export default motels;
