import { GET_DETAIL_BY_ID } from "redux/constants/Motels";
import { GET_LIST_REPORT } from "redux/constants/Report";

const initState = {
  reports: { data: [], pages: 0 },
  reportById: { data: {} },
};

const report = (state = initState, action) => {
  const { data = [], pages = 0 } = action;
  switch (action.type) {
    case GET_LIST_REPORT:
      return { ...state, reports: { ...state.reports, data, pages } };

    case GET_DETAIL_BY_ID:
      return { ...state, reportById: { ...state.reports, data } };

    default:
      return state;
  }
};

export default report;
