import { all, call, fork, put, takeEvery } from "@redux-saga/core/effects";
import { getListObjectRentSuccess } from "redux/actions/ObjectRent";
import { GET_LIST_OBJECT_RENT_PENDING } from "redux/constants/ObjectRent";
import ObjectRentService from "services/objectRent";

export function* getListObjectRent() {
  yield takeEvery(GET_LIST_OBJECT_RENT_PENDING, function* ({ params }) {
    try {
      const reports = yield call(ObjectRentService.fetchGetList, { ...params });
      const { data = [], pages = 0 } = reports.data || {};
      const payload = { data, pages };

      yield put(getListObjectRentSuccess(payload));
    } catch (err) {}
  });
}
export default function* rootSaga() {
  yield all([fork(getListObjectRent)]);
}
