export const GETLISTPENDING = "MOTEL:GET-LIST-PENDING";
export const GETLIST = "MOTEL:GET-LIST";

export const GETLISTWAITINGPENDING = "MOTEL:GET-LIST-WAITING-PENDING";
export const GETLISTWAITING = "MOTEL:GET-LIST-WAITING";

export const GET_DETAIL_BY_ID_PENDING = "MOTEL:GET-DETAIL-BY-ID-PENDING";
export const GET_DETAIL_BY_ID = "MOTEL:GET-DETAIL-BY-ID";

export const CREATE_MOTEL_PENDING = "MOTEL:CREATE-MOTEL-PENDING";
export const CREATE_MOTEL = "MOTEL:CREATE-MOTEL";

export const UPDATE_MOTEL_PENDING = "MOTEL:UPDATE-MOTEL-PENDING";
export const UPDATE_MOTEL = "MOTEL:UPDATE-MOTEL";
