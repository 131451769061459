import {
  GET_LIST_MOTEL_TYPE,
  GET_LIST_MOTEL_TYPE_PENDING,
} from "redux/constants/MotelType";

export const getListMotelType = (params) => {
  return { type: GET_LIST_MOTEL_TYPE_PENDING, params };
};

export const ListMotelType = (data, pages) => {
  return { type: GET_LIST_MOTEL_TYPE, data, pages };
};
