import {
  GETLIST,
  GETLISTPENDING,
  GETLISTWAITING,
  GETLISTWAITINGPENDING,
  GET_DETAIL_BY_ID,
} from "redux/constants/Motels";

const initState = {
  motels: { data: [], loading: false, pages: 0 },
  motelDetailById: { data: {} },
  motelsWaiting: { data: [], loading: false, pages: 0 },
};

const motels = (state = initState, action) => {
  const { data = [], pages } = action;
  switch (action.type) {
    case GETLISTPENDING:
      return { ...state, motels: { ...state.motels, loading: true } };

    case GETLIST:
      return {
        ...state,
        motels: { ...state.motels, data, pages, loading: false },
      };

    case GETLISTWAITINGPENDING:
      return {
        ...state,
        motelsWaiting: { ...state.motelsWaiting, loading: true },
      };

    case GETLISTWAITING:
      return {
        ...state,
        motelsWaiting: { ...state.motelsWaiting, data, pages, loading: false },
      };

    case GET_DETAIL_BY_ID:
      return {
        ...state,
        motelDetailById: { ...state.motelDetailById, data: action.data },
      };
    default:
      return state;
  }
};

export default motels;
