import { GET_LIST_OBJECT_RENT } from "redux/constants/ObjectRent";

const initState = {
  objectRent: { data: [], pages: 0 },
};

const objectRent = (state = initState, action) => {
  const { data = [], pages = 0 } = action;
  switch (action.type) {
    case GET_LIST_OBJECT_RENT:
      return { ...state, objectRent: { ...state.objectRent, data, pages } };

    default:
      return state;
  }
};

export default objectRent;
