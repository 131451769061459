import React, { Component } from "react";

export class Icon extends Component {
  render() {
    const { type, className } = this.props;
    return (
      <span
        className="anticon anticon-unordered-list"
        style={{ width: 22, display: "inline-flex" }}
      >
        {React.createElement(type, { className, size: 20 })}
      </span>
    );
  }
}

export default Icon;
