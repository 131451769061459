import { notification } from "antd";
import { all, call, fork, put, takeEvery } from "@redux-saga/core/effects";
import { showAuthMessage } from "redux/actions/Auth";
import {
  GetDetailMotelByID,
  ListMotel,
  ListMotelWaiting,
} from "redux/actions/Motels";
import {
  CREATE_MOTEL_PENDING,
  GETLISTPENDING,
  GETLISTWAITINGPENDING,
  GET_DETAIL_BY_ID_PENDING,
  UPDATE_MOTEL_PENDING,
} from "redux/constants/Motels";
import MotelService from "services/Motels";

export function* getListMotels() {
  yield takeEvery(GETLISTPENDING, function* ({ params }) {
    try {
      const motels = yield call(MotelService.getListMotels, { ...params });
      if (motels.message) yield put(showAuthMessage(motels.message));

      yield put(ListMotel(motels.data?.data || [], motels.data?.pages));
    } catch (err) {
      // yield put(showAuthMessage(err));
    }
  });
}

export function* getListMotelsWaiting() {
  yield takeEvery(GETLISTWAITINGPENDING, function* ({ params }) {
    try {
      const motels = yield call(MotelService.getListMotels, { ...params });
      if (motels.message) yield put(showAuthMessage(motels.message));

      yield put(ListMotelWaiting(motels.data?.data || [], motels.data?.pages));
    } catch (err) {
      // yield put(showAuthMessage(err));
    }
  });
}

export function* _fetchGetDetailByID() {
  yield takeEvery(GET_DETAIL_BY_ID_PENDING, function* ({ params }) {
    try {
      const motels = yield call(MotelService.getDetailMotelById, { ...params });

      yield put(GetDetailMotelByID(motels.data?.data || {}));
    } catch (err) {
      // yield put(showAuthMessage(err));
    }
  });
}

export function* _fetchCreateMotel() {
  yield takeEvery(CREATE_MOTEL_PENDING, function* ({ params }) {
    try {
      const motels = yield call(MotelService.creatMotel, params);
      if (motels?.data?.success) {
        return notification.success({ message: "Tạo phòng thành công" });
      }
      return notification.error({ message: "Tạo phòng thất bại" });
    } catch (err) {
      // return notification.error({
      //   message:
      //     err?.response?.data?.message || err.message || "Có lỗi khi tạo phòng",
      // });
    }
  });
}

export function* _fetchUpdateMotel() {
  yield takeEvery(UPDATE_MOTEL_PENDING, function* ({ params }) {
    try {
      const motels = yield call(MotelService.updateMotel, params);
      if (motels?.data?.success) {
        return notification.success({ message: "Cập nhật thành công" });
      }
      return notification.error({ message: "Cập nhật thất bại" });

      // yield put(GetDetailMotelByID(motels.data?.data || {}));
    } catch (err) {
      // return notification.error({
      //   message:
      //     err?.response?.data?.message || err.message || "Có lỗi khi cập nhật",
      // });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(getListMotels),
    fork(getListMotelsWaiting),
    fork(_fetchGetDetailByID),
    fork(_fetchCreateMotel),
    fork(_fetchUpdateMotel),
  ]);
}
