// import axios from "axios";
import service from "auth/FetchInterceptor";

export const getListCities = () => {
  return service.post("/address/cities");
};

export const getListDistricts = (params) => {
  return service.post("/address/districts", params);
};

export const getListWards = (params) => {
  return service.post("/address/wards", params);
};
