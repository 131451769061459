export const DataGender = [
  { value: "male", label: "Nam" },
  { value: "female", label: "Nữ" },
  { value: "other", label: "Khác" },
];

export const IServiceData = [
  { title: "Wifi", value: "isWifi" },
  { title: "Bãi giữ xe", value: "isHasParking" },
  { title: "Giờ giấc tự do", value: "isFreeTime" },
  { title: "Máy lạnh", value: "isHasAirconditioned" },
  { title: "Bảo vệ", value: "isSecurity" },
  { title: "Nhà vệ sinh riêng", value: "isHasOwnToilet" },
];

export const OptionWater = [
  { title: "VNĐ", value: "full" },
  { title: "VNĐ/m³", value: "part" },
];

export const ReportData = [
  { title: "Lừa đảo", placeHolder: "Bạn hãy mô tả dấu hiệu lừa đảo" },
  {
    title: "Trùng lặp",
    placeHolder: "Bạn hãy chia sẽ thêm về bài viết bị trùng lặp",
  },
  { title: "Không liên lạc được", placeHolder: "Bạn hãy mô tả thêm thông tin" },
  {
    title: "Thông tin không đúng với thực tế",
    placeHolder: "Bản hãy mô tả thêm thông tin",
  },
];
