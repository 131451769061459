export const CITIES_GET_LIST_PENDING = "CITIES/GET_LIST_PENDING";
export const CITIES_GET_LIST = "CITIES/GET_LIST";

export const DISTRICT_BY_CITIES_ID_PENDING =
  "DISTRICT/GET_LIST_BY_CITIES_ID_PENDING";
export const DISTRICT_BY_CITIES_ID = "DISTRICT/GET_LIST_BY_CITIES_ID";

export const WARD_BY_DISTRICT_ID_PENDING =
  "WARD/GET_LIST_BY_DISTRICT_ID_PENDING";
export const WARD_BY_DISTRICT_ID = "WARD/GET_LIST_BY_DISTRICT_ID";
