import { UserOutlined } from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import {
  BsGridFill,
  BsFillCalendarCheckFill,
  BsFillCalendarXFill,
  BsDiscFill,
  BsFillPeopleFill,
} from "react-icons/bs";
const userNavTree = [
  {
    key: "Room for rent",
    path: `${APP_PREFIX_PATH}/room`,
    title: "sidenav.roomForRent",
    icon: BsGridFill,
    breadcrumb: false,
    submenu: [
      {
        key: "RoomForRent-list",
        path: `${APP_PREFIX_PATH}/room`,
        title: "sidenav.roomForRent.list",
        icon: BsFillCalendarCheckFill,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "RoomForRent-waiting",
        path: `${APP_PREFIX_PATH}/room/waiting`,
        title: "sidenav.roomForRent.waiting",
        icon: BsDiscFill,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "RoomForRent-deleted",
        path: `${APP_PREFIX_PATH}/room/deleted`,
        title: "sidenav.roomForRent.deleted",
        icon: BsFillCalendarXFill,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: "REPORT POST",
    path: `${APP_PREFIX_PATH}/report`,
    title: "sidenav.report",
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "room-report-list",
        path: `${APP_PREFIX_PATH}/report/list-room`,
        title: "sidenav.report.listRoom",
        icon: BsDiscFill,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: "User",
    path: `${APP_PREFIX_PATH}/users`,
    title: "sidenav.user",
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "dashboards-default",
        path: `${APP_PREFIX_PATH}/users`,
        title: "sidenav.user.list",
        icon: BsFillPeopleFill,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...userNavTree];

export default navigationConfig;
