import { all, call, fork, put, takeEvery } from "@redux-saga/core/effects";
import { showAuthMessage } from "redux/actions/Auth";
import {
  listCities,
  listDistrictByCityId,
  listWardByDistrictId,
} from "redux/actions/Address";
import {
  CITIES_GET_LIST_PENDING,
  WARD_BY_DISTRICT_ID_PENDING,
  DISTRICT_BY_CITIES_ID_PENDING,
} from "redux/constants/address";
import {
  getListCities,
  getListDistricts,
  getListWards,
} from "services/address";

export function* getListCity() {
  yield takeEvery(CITIES_GET_LIST_PENDING, function* ({ params }) {
    try {
      const cities = yield call(getListCities, { ...params });
      if (cities.message) yield put(showAuthMessage(cities.message));

      yield put(listCities(cities.data?.data || [], cities.data?.pages));
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* getListWatd() {
  yield takeEvery(WARD_BY_DISTRICT_ID_PENDING, function* ({ params }) {
    try {
      const wards = yield call(getListWards, { ...params });

      yield put(
        listWardByDistrictId(wards.data?.data || [], wards.data?.pages)
      );
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* getListDistrict() {
  yield takeEvery(DISTRICT_BY_CITIES_ID_PENDING, function* ({ params }) {
    try {
      const districts = yield call(getListDistricts, { ...params });
      if (districts.message) yield put(showAuthMessage(districts.message));

      yield put(
        listDistrictByCityId(districts.data?.data || [], districts.data?.pages)
      );
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getListCity), fork(getListWatd), fork(getListDistrict)]);
}
