import { GET_DETAIL_BY_ID } from "redux/constants/Motels";
import {
  GET_LIST_REPORT_PENDING,
  GET_LIST_REPORT,
  GET_DETAIL_REPORT_PENDING,
} from "redux/constants/Report";

export const getListReport = (params) => {
  return { type: GET_LIST_REPORT_PENDING, params };
};

export const getListReportSuccess = (payload) => {
  return { type: GET_LIST_REPORT, data: payload.data, pages: payload.pages };
};

export const getDetailReportById = (params) => {
  return { type: GET_DETAIL_REPORT_PENDING, params };
};

export const getDetailReportByIdSuccess = (payload) => {
  return { type: GET_DETAIL_BY_ID, data: payload.data };
};
