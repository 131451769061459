import {
  CITIES_GET_LIST,
  WARD_BY_DISTRICT_ID,
  DISTRICT_BY_CITIES_ID,
} from "redux/constants/address";

const initState = {
  cities: { data: [], pages: 0 },
  districts: { data: [], pages: 0 },
  wards: { data: [], pages: 0 },
};

const address = (state = initState, action) => {
  const { data = [], pages = 0 } = action;
  switch (action.type) {
    case CITIES_GET_LIST:
      return { ...state, cities: { ...state.cities, data, pages } };
    case DISTRICT_BY_CITIES_ID:
      return { ...state, districts: { ...state.districts, data, pages } };
    case WARD_BY_DISTRICT_ID:
      return { ...state, wards: { ...state.wards, data, pages } };

    default:
      return state;
  }
};

export default address;
