// import axios from "axios";
import service from "auth/FetchInterceptor";
const ReportService = {};

ReportService.fetchGetList = (params) => {
  return service.post("/report/list", params);
};

ReportService.fetchDetailById = (params) => {
  return service.post("/report/detail", params);
};

ReportService.updateStatusReport = (params) => {
  return service.post("/report/update-report-motel", params);
};

export default ReportService;
