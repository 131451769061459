import {
  ADDCREATEUSER,
  CREATEUSER,
  FETCHUSERBYID,
  GETLIST,
  GETLISTPENDING,
  TRANSFERMONEY,
  FETCHUSERBYIDSUCCESS,
  FETCHUSERBYADMINIDSUCCESS,
  FETCHUSERBYADMINID,
} from "redux/constants/User";

export const GetListUsers = (params) => {
  return { type: GETLISTPENDING, params };
};
export const ListUsers = (data, pages, totalItems) => {
  return { type: GETLIST, data, pages, totalItems };
};

export const createUser = (data) => {
  return { type: CREATEUSER, data };
};

export const addUserToList = (data) => {
  return { type: ADDCREATEUSER, data };
};

export const transferMoneyToUser = (dataTransfer) => {
  return { type: TRANSFERMONEY, data: dataTransfer };
};

export const fetchUserById = (userId) => {
  return { type: FETCHUSERBYID, userId };
};

export const addUserToDetail = (data) => {
  return { type: FETCHUSERBYIDSUCCESS, data };
};

export const fetchUserProfileByAdminId = () => {
  return { type: FETCHUSERBYADMINID };
};

export const fetchUserProfileByAdminIdSuccess = (data) => {
  return { type: FETCHUSERBYADMINIDSUCCESS, data };
};
