// import axios from "axios";
import service from "auth/FetchInterceptor";
const MotelService = {};

MotelService.getListMotels = (params) => {
  return service.post("/motels/admin/get-list", params);
};

MotelService.getDetailMotelById = (params) => {
  return service.get("/motels/admin/detail-motel", { params });
};

MotelService.creatMotel = (params) => {
  return service.post("/motels/admin/create-motel", params);
};

MotelService.updateMotel = (params) => {
  return service.post("/motels/admin/update-motel", params);
};

MotelService.deleteMotel = (params) => {
  return service.post("/motels/admin/delete-motel", params);
};

export default MotelService;
