import { all, call, fork, put, takeEvery } from "@redux-saga/core/effects";
import {
  getDetailReportByIdSuccess,
  getListReportSuccess,
} from "redux/actions/Report";
import {
  GET_DETAIL_REPORT_PENDING,
  GET_LIST_REPORT_PENDING,
} from "redux/constants/Report";
import ReportService from "services/report";

export function* getListReport() {
  yield takeEvery(GET_LIST_REPORT_PENDING, function* ({ params }) {
    try {
      const reports = yield call(ReportService.fetchGetList, { ...params });

      const payload = {
        data: reports.data?.data || [],
        pages: reports.data?.pages || 0,
      };
      yield put(getListReportSuccess(payload));
    } catch (err) {}
  });
}

export function* getDetailReport() {
  yield takeEvery(GET_DETAIL_REPORT_PENDING, function* ({ params }) {
    try {
      const reports = yield call(ReportService.fetchDetailById, { ...params });

      const payload = { data: reports.data?.data || {} };
      yield put(getDetailReportByIdSuccess(payload));
    } catch (err) {}
  });
}

export default function* rootSaga() {
  yield all([fork(getListReport), fork(getDetailReport)]);
}
