import {
  GET_LIST_OBJECT_RENT_PENDING,
  GET_LIST_OBJECT_RENT,
} from "redux/constants/ObjectRent";

export const fetchListObjectRent = (params) => {
  return { type: GET_LIST_OBJECT_RENT_PENDING, params };
};

export const getListObjectRentSuccess = (payload) => {
  return {
    type: GET_LIST_OBJECT_RENT,
    data: payload.data,
    pages: payload.pages,
  };
};
