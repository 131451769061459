import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import history from "../history";
import { AUTH_TOKEN } from "redux/constants/Auth";
import { notification } from "antd";

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

// Config
const ENTRY_ROUTE = "/auth/login";
const TOKEN_PAYLOAD_KEY = "authorization";
const PUBLIC_REQUEST_KEY = "public-request";

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN);

    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = ["Bearer", jwtToken]
        .filter(Boolean)
        .join(" ");
    }

    if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
      history.push(ENTRY_ROUTE);
    }

    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: "Error",
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => response,
  (error) => {
    let notificationParam = {
      message:
        error?.response?.data?.message || error.message || "Có lỗi xảy ra",
    };

    // Remove token and redirect
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      notificationParam.message = "Authentication Fail";
      notificationParam.description = "Please login again";
      localStorage.removeItem(AUTH_TOKEN);
      history.push(ENTRY_ROUTE);
      if (error?.response?.status === 401) window.location.reload();
    }

    if (error?.response?.status === 404) {
      notificationParam.message = "Not Found";
    }

    if (error?.response?.status === 500) {
      notificationParam.message = "Internal Server Error";
    }

    if (error?.response?.status === 508) {
      notificationParam.message = "Time Out";
    }

    if (error?.response?.status === 413) {
      notificationParam.message = "File upload quá lớn, vui lòng upload lại!";
    }

    notification.error(notificationParam);

    return Promise.reject(error);
  }
);

export default service;

export { service };
