import {
  ADDCREATEUSER,
  GETLIST,
  FETCHUSERBYIDSUCCESS,
  GETLISTPENDING,
  FETCHUSERBYID,
  FETCHUSERBYADMINIDSUCCESS,
} from "redux/constants/User";

const initState = {
  users: { data: [], loading: false, pages: 0, totalItems: 0 },
  userById: { data: {}, loading: false },
  adminProfileById: { data: {}, loading: false },
};

const users = (state = initState, action) => {
  const { data = [], pages } = action;
  switch (action.type) {
    case GETLISTPENDING:
      return { ...state, users: { ...state.users, loading: true } };
    case GETLIST:
      return {
        ...state,
        users: {
          ...state.users,
          data,
          pages,
          totalItems: action.totalItems || 0,
          loading: false,
        },
      };

    case ADDCREATEUSER:
      return {
        ...state,
        users: {
          ...state.users,
          data: [...state.users.data, ...action.data],
          loading: false,
        },
      };

    case FETCHUSERBYID:
      return {
        ...state,
        userById: { ...state.userById, data: {}, loading: true },
      };
    case FETCHUSERBYIDSUCCESS:
      return {
        ...state,
        userById: { ...state.userById, data, loading: false },
      };

    case FETCHUSERBYADMINIDSUCCESS:
      return {
        ...state,
        adminProfileById: { ...state.adminProfileById, data },
      };
    default:
      return state;
  }
};

export default users;
