import {
  CREATE_MOTEL,
  CREATE_MOTEL_PENDING,
  GETLIST,
  GETLISTPENDING,
  GETLISTWAITING,
  GETLISTWAITINGPENDING,
  GET_DETAIL_BY_ID,
  GET_DETAIL_BY_ID_PENDING,
  UPDATE_MOTEL,
  UPDATE_MOTEL_PENDING,
} from "redux/constants/Motels";

export const GetListMotels = (params) => ({ type: GETLISTPENDING, params });

export const ListMotel = (data, pages) => ({ type: GETLIST, data, pages });

export const GetListMotelsWaiting = (params) => ({
  type: GETLISTWAITINGPENDING,
  params,
});

export const ListMotelWaiting = (data, pages) => ({
  type: GETLISTWAITING,
  data,
  pages,
});

export const fetchDetailMotelByID = (params) => ({
  type: GET_DETAIL_BY_ID_PENDING,
  params,
});

export const GetDetailMotelByID = (data) => ({ type: GET_DETAIL_BY_ID, data });

export const fetchCreateMotel = (params) => ({
  type: CREATE_MOTEL_PENDING,
  params,
});

export const fetchUpdateMotel = (params) => ({
  type: UPDATE_MOTEL_PENDING,
  params,
});

export const createMotelSuccess = (data) => ({ type: CREATE_MOTEL, data });

export const updarwMotelSuccess = (data) => ({ type: UPDATE_MOTEL, data });
