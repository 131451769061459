import { combineReducers } from "redux";
import auth from "./Auth";
import theme from "./Theme";
import user from "./User";
import motels from "./Motels";
import motelType from "./MotelType";
import address from "./address";
import report from "./report";
import objectRent from "./ObjectRent";
const reducers = combineReducers({
  theme,
  auth,
  user,
  motels,
  motelType,
  address,
  report,
  objectRent,
});

export default reducers;
