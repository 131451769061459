import { all, call, fork, put, takeEvery } from "@redux-saga/core/effects";
import { showAuthMessage } from "redux/actions/Auth";
import { ListMotelType } from "redux/actions/MotelType";
import { GET_LIST_MOTEL_TYPE_PENDING } from "redux/constants/MotelType";
import { getListMotelTypes } from "services/motelType";

export function* getListMotelType() {
  yield takeEvery(GET_LIST_MOTEL_TYPE_PENDING, function* ({ params }) {
    try {
      const motels = yield call(getListMotelTypes, { ...params });
      if (motels.message) yield put(showAuthMessage(motels.message));

      yield put(ListMotelType(motels.data?.data || [], motels.data?.pages));
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}
export default function* rootSaga() {
  yield all([fork(getListMotelType)]);
}
