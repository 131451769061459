import { all, call, fork, put, takeEvery } from "@redux-saga/core/effects";
import { notification } from "antd";
import { showAuthMessage } from "redux/actions/Auth";
import {
  addUserToList,
  ListUsers,
  addUserToDetail,
  fetchUserProfileByAdminIdSuccess,
} from "redux/actions/User";
import {
  CREATEUSER,
  GETLISTPENDING,
  FETCHUSERBYID,
  FETCHUSERBYADMINID,
} from "redux/constants/User";
import UserService from "services/User";

export function* getListUsers() {
  yield takeEvery(GETLISTPENDING, function* ({ params }) {
    try {
      const users = yield call(UserService.getListUsers, { ...params });
      if (users.message) yield put(showAuthMessage(users.message));

      yield put(
        ListUsers(
          users.data?.data || [],
          users.data?.pages,
          users.data?.totalItems
        )
      );
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* CreateUsers() {
  yield takeEvery(CREATEUSER, function* ({ data }) {
    try {
      const { email, password } = data;
      const users = yield call(UserService.createUser, {
        username: email,
        password,
      });
      if (users?.data?.message) yield put(showAuthMessage(users.data?.message));
      yield put(addUserToList(users.data?.data || {}));
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* FetchUserById() {
  yield takeEvery(FETCHUSERBYID, function* ({ userId }) {
    try {
      const dataUser = yield call(UserService.fetchUserDetailById, {
        ...userId,
      });
      yield put(addUserToDetail(dataUser.data?.data || {}));
    } catch (err) {
      notification.error({
        message: err.response?.data?.message || err.message || "",
      });
      yield put(showAuthMessage(err));
    }
  });
}

export function* FetchProfileByAdmin() {
  yield takeEvery(FETCHUSERBYADMINID, function* () {
    try {
      const dataUser = yield call(UserService.fetchUserProfileByAdmin);
      yield put(fetchUserProfileByAdminIdSuccess(dataUser.data?.data || {}));
    } catch (err) {
      notification.error({
        message: err.response?.data?.message || err.message || "",
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getListUsers),
    fork(CreateUsers),
    fork(FetchUserById),
    fork(FetchProfileByAdmin),
  ]);
}
